<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card class="p-5 p-md-8">
      <ModalHeading
        :modalTitle="formMode === 'add' ? 'Add Education' : 'Edit Education'"
        modalDesc="Fill in education degrees you have earned and are currently pursuing."
      />
      <form class="form pt-3" @submit.prevent="submitForm">
        <div class="row">
          <div class="col-12 mb-7">
            <label class="form__label">
              School / University <span class="text-danger">*</span></label
            >
            <v-text-field
              outlined
              dense
              hide-details
              placeholder="School / University"
              v-model="education.university"
              @blur="$v.education.university.$touch()"
            >
            </v-text-field>
            <VulidateError
              :validationField="$v.education.university"
              :params="[
                'required',
                'alphaNumericWithSpecialChars',
                {
                  maxLength: {
                    number: $v.education.university.$params.maxLength.max,
                  },
                },
              ]"
            />
          </div>
          <div class="col-md-6 mb-7">
            <label class="form__label">Degree</label>
            <v-text-field
              outlined
              dense
              hide-details
              placeholder="Degree"
              v-model="education.degree"
              @blur="$v.education.degree.$touch()"
            >
            </v-text-field>
            <VulidateError
              :validationField="$v.education.degree"
              :params="[
                'alphaNumericWithSpecialChars',
                {
                  maxLength: {
                    number: $v.education.degree.$params.maxLength.max,
                  },
                },
              ]"
            />
          </div>
          <div class="col-md-6 mb-7">
            <label class="form__label"
              >Field of study <span class="text-danger">*</span></label
            >
            <v-text-field
              outlined
              dense
              hide-details
              placeholder="Field of study"
              v-model="education.study_field"
              @blur="$v.education.study_field.$touch()"
            >
            </v-text-field>
            <VulidateError
              :validationField="$v.education.study_field"
              :params="[
                'required',
                'alphaNumericWithSpecialChars',
                {
                  maxLength: {
                    number: $v.education.study_field.$params.maxLength.max,
                  },
                },
              ]"
            />
          </div>
          <div class="col-12 mb-7 v-checkbox d-flex">
            <v-checkbox
              v-model="education.currently"
              :false-value="0"
              :true-value="1"
              label="I am undergraduate"
              hide-details
              @change="checkEndDate"
            ></v-checkbox>
          </div>
          <div class="col-md-6 mb-7">
            <label class="form__label"
              >Start Date <span class="text-danger">*</span></label
            >
            <div class="row">
              <div class="col-6">
                <v-select
                  :items="MONTHS"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  hide-details
                  placeholder="Select Month"
                  v-model="education.start_month"
                  @blur="$v.education.start_month.$touch()"
                  :menu-props="{ bottom: true }"
                ></v-select>
                <VulidateError
                  :validationField="$v.education.start_month"
                  :params="['required']"
                />
              </div>
              <div class="col-6">
                <v-select
                  :items="YEARS"
                  outlined
                  dense
                  hide-details
                  placeholder="Select Year"
                  v-model="education.start_year"
                  @blur="$v.education.start_year.$touch()"
                  :menu-props="{ bottom: true }"
                ></v-select>
                <VulidateError
                  :validationField="$v.education.start_year"
                  :params="['required']"
                />
              </div>
            </div>
            <div
              class="form__error-label"
              v-if="
                education.start_month &&
                education.start_year &&
                $v.$anyDirty &&
                $v.validStartDate.$error
              "
            >
              Please Enter a Valid Date
            </div>
            <div
              class="form__error-label"
              v-if="
                education.start_month &&
                education.start_year &&
                education.end_month &&
                education.end_year &&
                $v.$anyDirty &&
                $v.validEndDate.$error
              "
            >
              Start date must be before End date
            </div>
          </div>

          <div class="col-md-6 mb-7">
            <label class="form__label"
              >End Date <span class="text-danger">*</span></label
            >
            <div class="row">
              <div class="col-6">
                <v-select
                  :items="MONTHS"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  hide-details
                  placeholder="Select Month"
                  v-model="education.end_month"
                  @blur="$v.education.end_month.$touch()"
                  :menu-props="{ bottom: true }"
                  :disabled="!!education.currently"
                ></v-select>
                <VulidateError
                  :validationField="$v.education.end_month"
                  :params="['required']"
                />
              </div>
              <div class="col-6">
                <v-select
                  :items="YEARS"
                  outlined
                  dense
                  hide-details
                  placeholder="Select Year"
                  v-model="education.end_year"
                  @blur="$v.education.end_year.$touch()"
                  :menu-props="{ bottom: true }"
                  :disabled="!!education.currently"
                ></v-select>
                <VulidateError
                  :validationField="$v.education.end_year"
                  :params="['required']"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="text-right pt-2">
          <button
            class="btn btn-secondary font-weight-bolder font-size-h6 px-8"
            type="button"
            @click="resetModal"
          >
            Cancel
          </button>
          <button
            class="btn btn-info font-weight-bolder font-size-h6 px-8 ml-5"
            :class="{
              'spinner spinner-light spinner-right': loading,
            }"
            :disabled="loading"
          >
            Save
          </button>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  requiredIf,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
import ModalHeading from "@/components/UI/ModalHeading.vue";
import VulidateError from "@/components/shared/vulidateError.vue";
import MONTHS from "@/constants/months";
import YEARS from "@/constants/years";
import {
  lessThanOrEqualCurrentDate,
  validDate,
  alphaNumericWithSpecialChars,
} from "@/helpers";

const initEducationObj = {
  university: "",
  degree: "",
  study_field: "",
  currently: 0,
  start_year: null,
  start_month: null,
  end_year: null,
  end_month: null,
};
export default {
  name: "educationFormModal",
  mixins: [validationMixin],
  components: {
    ModalHeading,
    VulidateError,
  },
  props: {
    activeEducation: {
      required: true,
    },
    educationHasAnEmptyRow: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      formMode: "add",
      education: { ...initEducationObj },
      MONTHS,
      YEARS,
    };
  },
  watch: {
    activeEducation(newVal) {
      if (newVal) {
        this.formMode = "edit";
        this.education = { ...newVal };
        if (this.educationHasAnEmptyRow.find((e) => e.id === newVal.id)) {
          setTimeout(() => {
            this.$v.$touch();
          }, 100);
        }
      } else {
        this.formMode = "add";
        this.education = { ...initEducationObj };
      }
    },
  },
  validations: {
    education: {
      university: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars,
      },
      degree: {
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars,
      },
      study_field: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars,
      },
      start_month: {
        required,
      },
      start_year: {
        required,
      },
      end_month: {
        required: requiredIf(function () {
          return !this.education.currently;
        }),
      },
      end_year: {
        required: requiredIf(function () {
          return !this.education.currently;
        }),
      },
    },
    validStartDate: {
      sameAs: sameAs(() => true),
    },
    validEndDate: {
      sameAs: sameAs(() => true),
    },
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingStatus",
    }),
    validStartDate() {
      return (
        this.education.start_month &&
        this.education.start_year &&
        lessThanOrEqualCurrentDate(
          this.education.start_month,
          this.education.start_year
        )
      );
    },
    validEndDate() {
      if (this.education.currently) return true;
      return (
        this.education.start_month &&
        this.education.start_year &&
        this.education.end_month &&
        this.education.end_year &&
        validDate(
          this.education.start_month,
          this.education.start_year,
          this.education.end_month,
          this.education.end_year
        )
      );
    },
  },
  methods: {
    ...mapActions({
      addTalentEducation: "talentProfile/addTalentEducation",
      editTalentEducation: "talentProfile/editTalentEducation",
    }),
    checkEndDate(value) {
      if (value) {
        this.education.end_month = "";
        this.education.end_year = "";
        this.$v.education.end_month.$reset();
        this.$v.education.end_year.$reset();
      }
    },
    resetModal() {
      this.dialog = false;
      this.$v.$reset();
      this.education = { ...initEducationObj };
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      let payload = {
        data: this.education,
        talent_id: this.$route.params.id,
      };
      if (this.formMode === "add") {
        this.addTalentEducation(payload).then(() => {
          this.resetModal();
        });
      } else {
        if (
          !this.activeEducation ||
          (this.activeEducation.is_parsed_from_cv && !this.activeEducation.id)
        ) {
          payload.edit = true;
          this.addTalentEducation(payload).then(() => {
            this.resetModal();
            this.$emit("getImportedCv");
          });
        } else {
          this.editTalentEducation(payload).then(() => {
            this.resetModal();
            this.$emit("getImportedCv");
          });
        }
      }
    },
  },
};
</script>
