<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card class="p-5 p-md-8">
      <ModalHeading
        :modalTitle="formMode === 'add' ? 'Add Experience' : 'Edit Experience'"
        modalDesc="Fill in data related to your previous jobs."
      />
      <form class="form pt-3" @submit.prevent="submitForm">
        <div class="row">
          <div class="col-md-6 mb-7">
            <label class="form__label"
              >Job Title <span class="text-danger">*</span></label
            >
            <v-text-field
              outlined
              dense
              hide-details
              placeholder="Job Title"
              v-model="experience.title"
              @blur="$v.experience.title.$touch()"
            >
            </v-text-field>
            <VulidateError
              :validationField="$v.experience.title"
              :params="[
                'required',
                'alphaNumericWithSpecialChars',
                {
                  maxLength: {
                    number: $v.experience.title.$params.maxLength.max,
                  },
                },
              ]"
            />
          </div>
          <div class="col-md-6 mb-7">
            <label class="form__label"
              >Company <span class="text-danger">*</span></label
            >
            <v-text-field
              outlined
              dense
              hide-details
              placeholder="Company"
              v-model="experience.company"
              @blur="$v.experience.company.$touch()"
            >
            </v-text-field>
            <VulidateError
              :validationField="$v.experience.company"
              :params="[
                'required',
                'alphaNumericWithSpecialChars',
                {
                  maxLength: {
                    number: $v.experience.company.$params.maxLength.max,
                  },
                },
              ]"
            />
          </div>
          <div class="col-12 mb-7 v-checkbox d-flex">
            <v-checkbox
              v-model="experience.currently_work_in"
              :false-value="0"
              :true-value="1"
              label="I currently work here"
              hide-details
              @change="checkEndDate"
            ></v-checkbox>
          </div>
          <div class="col-md-6 mb-7">
            <label class="form__label"
              >Start Date <span class="text-danger">*</span></label
            >
            <div class="row">
              <div class="col-6">
                <v-select
                  :items="MONTHS"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  hide-details
                  placeholder="Select Month"
                  v-model="experience.start_month"
                  @blur="$v.experience.start_month.$touch()"
                  :menu-props="{ bottom: true }"
                ></v-select>
                <VulidateError
                  :validationField="$v.experience.start_month"
                  :params="['required']"
                />
              </div>
              <div class="col-6">
                <v-select
                  :items="YEARS"
                  outlined
                  dense
                  hide-details
                  placeholder="Select Year"
                  v-model="experience.start_year"
                  @blur="$v.experience.start_year.$touch()"
                  :menu-props="{ bottom: true }"
                ></v-select>
                <VulidateError
                  :validationField="$v.experience.start_year"
                  :params="['required']"
                />
              </div>
            </div>
            <div
              class="form__error-label"
              v-if="
                experience.start_month &&
                experience.start_year &&
                $v.$anyDirty &&
                $v.validStartDate.$error
              "
            >
              Please Enter a Valid Date
            </div>
            <div
              class="form__error-label"
              v-if="
                experience.start_month &&
                experience.start_year &&
                experience.end_month &&
                experience.end_year &&
                $v.$anyDirty &&
                $v.validEndDate.$error
              "
            >
              Start date must be before End date
            </div>
          </div>

          <div class="col-md-6 mb-7">
            <label class="form__label"
              >End Date <span class="text-danger">*</span></label
            >
            <div class="row">
              <div class="col-6">
                <v-select
                  :items="MONTHS"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  hide-details
                  placeholder="Select Month"
                  v-model="experience.end_month"
                  @blur="$v.experience.end_month.$touch()"
                  :menu-props="{ bottom: true }"
                  :disabled="!!experience.currently_work_in"
                ></v-select>
                <VulidateError
                  :validationField="$v.experience.end_month"
                  :params="['required']"
                />
              </div>
              <div class="col-6">
                <v-select
                  :items="YEARS"
                  outlined
                  dense
                  hide-details
                  placeholder="Select Year"
                  v-model="experience.end_year"
                  @blur="$v.experience.end_year.$touch()"
                  :menu-props="{ bottom: true }"
                  :disabled="!!experience.currently_work_in"
                ></v-select>
                <VulidateError
                  :validationField="$v.experience.end_year"
                  :params="['required']"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mb-7">
            <label class="form__label"
              >Responsibility <span class="text-danger">*</span></label
            >
            <v-textarea
              outlined
              dense
              hide-details
              placeholder="Describe your role in this company"
              v-model="experience.description"
              @blur="$v.experience.description.$touch()"
              class="custom-scroll"
            >
            </v-textarea>
            <VulidateError
              :validationField="$v.experience.description"
              :params="[
                'required',
                {
                  maxLength: {
                    number: $v.experience.description.$params.maxLength.max,
                  },
                },
              ]"
            />
          </div>
        </div>
        <div class="text-right">
          <button
            class="btn btn-secondary font-weight-bolder font-size-h6 px-8"
            type="button"
            @click="resetModal"
          >
            Cancel
          </button>
          <button
            class="btn btn-info font-weight-bolder font-size-h6 px-8 ml-5"
            :class="{
              'spinner spinner-light spinner-right': loading,
            }"
            :disabled="loading"
          >
            Save
          </button>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  requiredIf,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
import ModalHeading from "@/components/UI/ModalHeading.vue";
import VulidateError from "@/components/shared/vulidateError.vue";
import MONTHS from "@/constants/months";
import YEARS from "@/constants/years";
import {
  lessThanOrEqualCurrentDate,
  validDate,
  alphaNumericWithSpecialChars,
} from "@/helpers";

const initExperienceObj = {
  title: "",
  company: "",
  description: "",
  currently_work_in: 0,
  start_year: null,
  start_month: null,
  end_year: null,
  end_month: null,
};
export default {
  name: "ExperienceFormModal",
  mixins: [validationMixin],
  components: {
    ModalHeading,
    VulidateError,
  },
  props: {
    activeExperience: {
      required: true,
    },
    experienceHasAnEmptyRow: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      formMode: "add",
      experience: { ...initExperienceObj },
      MONTHS,
      YEARS,
    };
  },
  watch: {
    activeExperience(newVal) {
      if (newVal) {
        this.formMode = "edit";
        this.experience = { ...newVal };
        if (this.experienceHasAnEmptyRow.find((e) => e.id === newVal.id)) {
          setTimeout(() => {
            this.$v.$touch();
          }, 100);
        }
      } else {
        this.formMode = "add";
        this.experience = { ...initExperienceObj };
      }
    },
  },
  validations: {
    experience: {
      title: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars,
      },
      company: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars,
      },
      description: {
        required,
        maxLength: maxLength(5000),
      },
      start_month: {
        required,
      },
      start_year: {
        required,
      },
      end_month: {
        required: requiredIf(function () {
          return !this.experience.currently_work_in;
        }),
      },
      end_year: {
        required: requiredIf(function () {
          return !this.experience.currently_work_in;
        }),
      },
    },
    validStartDate: {
      sameAs: sameAs(() => true),
    },
    validEndDate: {
      sameAs: sameAs(() => true),
    },
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingStatus",
    }),
    validStartDate() {
      return (
        this.experience.start_month &&
        this.experience.start_year &&
        lessThanOrEqualCurrentDate(
          this.experience.start_month,
          this.experience.start_year
        )
      );
    },
    validEndDate() {
      if (this.experience.currently_work_in) return true;
      return (
        this.experience.start_month &&
        this.experience.start_year &&
        this.experience.end_month &&
        this.experience.end_year &&
        validDate(
          this.experience.start_month,
          this.experience.start_year,
          this.experience.end_month,
          this.experience.end_year
        )
      );
    },
  },
  methods: {
    ...mapActions({
      addTalentExperience: "talentProfile/addTalentExperience",
      editTalentExperience: "talentProfile/editTalentExperience",
    }),
    checkEndDate(value) {
      if (value) {
        this.experience.end_month = "";
        this.experience.end_year = "";
        this.$v.experience.end_month.$reset();
        this.$v.experience.end_year.$reset();
      }
    },
    resetModal() {
      this.dialog = false;
      this.$v.$reset();
      this.experience = { ...initExperienceObj };
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      let payload = {
        data: this.experience,
        talent_id: this.$route.params.id,
      };
      if (this.formMode === "add") {
        this.addTalentExperience(payload).then(() => {
          this.resetModal();
        });
      } else {
        if (
          !this.activeExperience ||
          (this.activeExperience.is_parsed_from_cv && !this.activeExperience.id)
        ) {
          payload.edit = true;
          this.addTalentExperience(payload).then(() => {
            this.resetModal();
          });
        } else {
          this.editTalentExperience(payload).then(() => {
            this.resetModal();
          });
        }
      }
    },
  },
};
</script>
