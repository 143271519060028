//get years
const getYearList = () => {
  // last year = current year + 8
  let lastYear = new Date().getFullYear() + 8;
  let years = [];
  for (let i = 0; i <= 50; i++) {
    years.push(lastYear - i);
  }
  return years;
};

export default getYearList();
